import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import FsLightbox from "fslightbox-react";

import { translateStatic } from "../../../../helpers/translationHelper";
import * as theme1Styles from "../../scss/theme1.module.scss";

const Video = ({ videos, locale, defaultLocale }) => {
  const [toggler, setToggler] = useState(false);
  const [slide, setSlide] = useState(0);

  const handleImageOnClick = (toggler, slide) => {
    setToggler(!toggler);
    setSlide(slide);
  };

  return (
    <Fragment>
      <FsLightbox
        toggler={toggler}
        slide={slide}
        sources={videos.map(
          (video) => `https://www.youtube.com/embed/${video.youtube_video_id}`
        )}
      />
      <h3>{translateStatic("videos", locale, defaultLocale)}</h3>
      <div className={`${theme1Styles.mediaRow}`}>
        {videos.map((video, i) => {
          return (
            <div
              className={theme1Styles.colm}
              key={i}
              onClick={() => handleImageOnClick(toggler, i + 1)}
            >
              <a
                href={video.video_url}
                onClick={(e) => e.preventDefault()}
                className={`${theme1Styles.videoBox}`}
              >
                <div className={theme1Styles.image}>
                  <img alt="image" src={video.fetched_thumbnail_url} />
                </div>
                <div className={theme1Styles.desc}>{video.video_title}</div>
              </a>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

Video.propTypes = {
  videos: PropTypes.array.isRequired,
  locale: PropTypes.string,
  defaultLocale: PropTypes.string,
};

export default Video;
