import React from "react";
import PropTypes from "prop-types";

import ImageModal from "../../../../common/ImageModal";
import { translateStatic } from "../../../../helpers/translationHelper";
import * as theme2Styles from "../../scss/theme2.module.scss";

const Image = ({ pictures, locale, defaultLocale }) => {
  return (
    <>
      <h3>{translateStatic("pictures", locale, defaultLocale)}</h3>
      <ImageModal
        themeStyles={theme2Styles}
        images={pictures}
      />
    </>
  );
};

Image.propTypes = {
  pictures: PropTypes.array.isRequired,
  locale: PropTypes.string,
  defaultLocale: PropTypes.string,
};

export default Image;
