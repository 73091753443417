import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import FsLightbox from "fslightbox-react";

import SiteMetaContext from "../../../../contexts/SiteMetaContext";

import * as theme4Styles from "../../scss/theme4.module.scss";

const Media = ({ pictures }) => {
  const [toggler, setToggler] = useState(false);
  const [slide, setSlide] = useState(0);

  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          id: website_id,
          setting: {
            display_watermark
          }
        }
      }
    }
  } = siteData;

  const handleImageOnClick = (toggler, slide) => {
    setToggler(!toggler);
    setSlide(slide);
  };

  const sources = pictures.map((picture) =>
    display_watermark != 0 ? `${picture.watermark_url}?website=${website_id}` : picture.image_url
  );

  return (
    <div className={`${theme4Styles.detailsImagesC}`}>
      <FsLightbox
        type="image"
        toggler={toggler}
        slide={slide}
        sources={sources}
      />
      <div className={theme4Styles.detailsImages}>
        {pictures.slice(0, 3).map((picture, i) => {
          let url = null;
          if (i == 0) {
            url = display_watermark != 0 ? `${picture.watermark_url}?website=${website_id}` : picture.image_url;
          } else {
            url = display_watermark == 2 ? `${picture.watermark_url}?website=${website_id}` : picture.image_url;
          }
          return (
            <div
              className={`${theme4Styles.thumbView}`}
              key={i}
              onClick={() => handleImageOnClick(toggler, i + 1)}
            >
              <img alt="image" src={url} />
            </div>
          );
        })}
        {(() => {
          if (pictures.length == 4) {
            let url = display_watermark == 2 ? `${pictures[3].watermark_url}?website=${website_id}` : pictures[3].image_url;
            return (
              <div
                className={`${theme4Styles.thumbView}`}
                onClick={() => handleImageOnClick(toggler, 4)}
              >
                <img alt="image" src={url} />
              </div>
            );
          } else if (pictures.length > 4) {
            let url = display_watermark == 2 ? `${pictures[3].watermark_url}?website=${website_id}` : pictures[3].image_url;
            return (
              <div
                className={`${theme4Styles.thumbView}`}
                onClick={() => handleImageOnClick(toggler, 4)}
              >
                <img alt="image" src={url} />
                <a href="#" onClick={e => e.preventDefault()} className={theme4Styles.total}>
                  <span>View All</span>
                </a>
              </div>
            );
          }
        })()}
        <div className={theme4Styles.clear}></div>
      </div>
    </div>
  );
};

Media.propTypes = {
  pictures: PropTypes.array.isRequired,
};

export default Media;
