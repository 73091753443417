import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { translateStatic } from "../../../../helpers/translationHelper";
import * as theme5Styles from "../../scss/theme5.module.scss";

import pdf from "../../images/pdf-icon.png";
import doc from "../../images/doc-icon.png";

function composeDocument(document) {
  const { uuid, filetype, title, url } = document;

  let icon = doc;
  if (filetype == "application/pdf") {
    icon = pdf;
  }

  return (
    <div className={theme5Styles.colm} key={uuid}>
      <a
        to={url}
        target="_blank"
        className={`${theme5Styles.docBox}`}
        rel="noreferrer"
      >
        <img alt="image" src={icon} className={theme5Styles.ico} />
        <span className={theme5Styles.desc}>{title}</span>
      </a>
    </div>
  );
}

const Document = ({ documents, locale, defaultLocale }) => {
  return (
    <Fragment>
      <h3>{translateStatic("documents", locale, defaultLocale)}</h3>
      <div className={`${theme5Styles.docRow}`}>
        {documents.map((document) => composeDocument(document))}
      </div>
    </Fragment>
  );
};

Document.propTypes = {
  documents: PropTypes.array.isRequired,
  locale: PropTypes.string,
  defaultLocale: PropTypes.string,
};

export default Document;
