import React, { Fragment } from "react";
import PropTypes from "prop-types";

import {
  getTranslatedValue,
  translateStatic,
} from "../../../../helpers/translationHelper";
import * as theme4Styles from "../../scss/theme4.module.scss";

// import resort from "../../images/resort-icon.svg";

const Feature = ({ services, amenities, locale, defaultLocale }) => {
  return (
    <Fragment>
      <h3>{translateStatic("features", locale, defaultLocale)}</h3>
      <div className={`${theme4Styles.propFeatures}`}>
        {services.map((service) => {
          return (
            <div className={theme4Styles.colm} key={service.id}>
              {/* <span className={theme4Styles.ico}>
                                    <img alt="image" src={resort} />
                                </span> */}
              <span className={theme4Styles.nm}>
                {getTranslatedValue(
                  service.translations,
                  "name",
                  locale,
                  defaultLocale
                )}
              </span>
            </div>
          );
        })}
        {amenities.map((amenity) => {
          return (
            <div className={theme4Styles.colm} key={amenity.id}>
              {/* <span className={theme4Styles.ico}>
                                    <img alt="image" src={resort} />
                                </span> */}
              <span className={theme4Styles.nm}>
                {getTranslatedValue(
                  amenity.translations,
                  "name",
                  locale,
                  defaultLocale
                )}
              </span>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

Feature.propTypes = {
  services: PropTypes.array.isRequired,
  amenities: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired,
};

export default Feature;
