/* eslint react/prop-types: 0 */
import React, { useState, useContext } from "react";
import FsLightbox from "fslightbox-react";

import SiteMetaContext from "../contexts/SiteMetaContext";
import { prepareImageURL } from "../helpers/helper";

// eslint-disable-next-line react/prop-types
const ImageModal = ({ images, themeStyles }) => {
  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          id: website_id,
          setting: {
            display_watermark
          }
        }
      }
    }
  } = siteData;

  // if toggler is updated when lightbox is closed it will open it
  // if toggler is updated when lightbox is opened it will close it
  const [toggler, setToggler] = useState(false);
  const [slide, setSlide] = useState(0);

  const handleImageOnClick = (toggler, slide) => {
    setToggler(!toggler);
    setSlide(slide);
  };

  const sources = images.map((image) =>
    display_watermark != 0 ? `${image.watermark_url}?website=${website_id}` : image.image_url
  );

  return (
    <div className={`${themeStyles.mediaRow}`}>
      <FsLightbox
        type="image"
        toggler={toggler}
        slide={slide}
        sources={sources}
      />
      {images.map((image, i) => {
        return (
          <div className={themeStyles.colm} key={i}>
            <div
              className={`${themeStyles.mediaBox}`}
              onClick={() => handleImageOnClick(toggler, i + 1)}
            >
              <img src={prepareImageURL(image, website_id, 480, 320, display_watermark == 2)} alt="" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ImageModal;
